<template>
  <div class="list row px-0 mx-0 ">
    <div class="col-md-12">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Leita..." v-model="Name" />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" @click="searchName">
            Leita
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="d-flex">
        <div class="p-2 align-self-center">
          <h4>Kuffert</h4>
        </div>

        <router-link to="/add-case"
          class="ml-auto p-2 align-self-center m-3 btn btn-sm btn-success  ">Stovna</router-link>

        <!-- <button
          class="ml-auto p-2 align-self-center m-3 btn btn-sm btn-danger  "
          @click="removeAllCases"
        >
          Rudda alt
        </button> -->
      </div>

      <ul class="list-group">
        <li class="list-group-item" :class="{ active: index == currentIndex }" v-for="(caseItem, index) in caseItems"
          :key="index" @click="setActiveCase(caseItem, index)">
          {{ caseItem.Name }} -- (items {{ caseItem.ItemCount }})
        </li>
      </ul>
    </div>
    <div class="col-md-4">
      <div v-if="currentCase">
        <h4>Case</h4>
        <div>
          <label><strong>Navn:</strong></label> {{ currentCase.Name }}
        </div>
        <div>
          <label><strong>Pakka Dato:</strong></label>
          {{ getFilteredDate(currentCase.PackingDate) }}
        </div>
        <div>
          <label><strong>Sent Dato:</strong></label>
          {{ getFilteredDate(currentCase.SentDate) }}
        </div>
        <div>
          <label><strong>Kundi:</strong></label>
          {{ currentCase.CustomerName }}
        </div>
        <div>
          <label><strong>Lutir:</strong></label>
          {{ caseDetails.length }}
          <br/>
          <router-link :to="'/case/' + currentCase.Id" class="badge badge-warning">Edit</router-link>
          <br />
          <router-link :to="'/caseitems/' + currentCase.Id + '/items'" class="badge badge-warning">Lutir</router-link>
          <ul class="list-group " v-if="caseDetails.length > 0">
            <li v-for="(ss, index) in caseDetails" :key="index" @click="setActiveCase(caseItem, index)">
              {{ ss.Name }}
            </li>
          </ul>
          <label v-else>Items not found</label>
        </div>

      </div>
      <!-- <div v-else>
        <br />
        <p>Vinarliga trýst á kuffert...</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import CaseDataService from "../../services/CaseDataService";

export default {
  name: "caseItems-list",
  data() {
    return {
      caseDetails: [],
      caseItems: [],
      currentCase: null,
      currentIndex: -1,
      Name: "",
    };
  },

  methods: {
    getFilteredDate(dt) {
      return new Date(dt).toISOString().substring(0, 10);
    },
    retrieveCases() {
      CaseDataService.getAll()
        .then((response) => {
          this.caseItems = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveCaseDetails(id) {
      CaseDataService.getDetails(id)
        .then((response) => {
          this.caseDetails = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    refreshList() {
      this.retrieveCases();
      this.currentCase = null;
      this.currentIndex = -1;
    },

    setActiveCase(caseItem, index) {
      this.retrieveCaseDetails(caseItem.Id);
      this.currentCase = caseItem;
      this.currentIndex = caseItem ? index : -1;
    },

    removeAllCases() {
      if (confirm("Are you sure you want to delete All Record!")) {
        CaseDataService.deleteAll()
          .then((response) => {
            this.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log("closed");
      }
    },

    searchName() {
      CaseDataService.findByName(this.Name)
        .then((response) => {
          this.caseItems = response.data;
          this.setActiveCase(null);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.retrieveCases();
  },
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
</style>
