import http from "../http-common";

class CaseDataService {
  getAll() {
    
    return http.get("/cases");
  }

  get(Id) {
    
    return http.get(`/cases/${Id}`);
  }

  getAllCustomerCases(Id){
    console.log('ID*', Id)
    return http.get(`/cases/customer/${Id}`)
  }

  getDetails(Id) {
    return http.get(`/cases/findDetails/${Id}`);
  }

  create(data) {
    return http.post("/cases", data);
  }

  update(Id, data) {
    return http.put(`/cases/${Id}`, data);
  }
  addItemToCase(Id, data){
    return http.post(`/cases/item/${Id}`, data)
  }
  addItem(Id, data){
    return http.put(`/cases/${Id}/additems`, data)
  }

  delete(Id) {
    return http.delete(`/cases/${Id}`);
  }

  deleteAll() {
    return http.delete(`/cases`);
  }

  findByName(Name) {
    return http.get(`/cases?Name=${Name}`);
  }
}

export default new CaseDataService();
